import { jsPDF } from "jspdf";
import type { RowInput, UserOptions } from "jspdf-autotable";
import { applyPlugin } from "jspdf-autotable";
import formatDate from "../format-date";
import { createSimpleTable, createFirstPage, createTeamsTableRow } from "./layout";

applyPlugin(jsPDF);

export function createReport(options: {
  fileName?: string;

  title?: string;
  description?: string;
  date?: string;
  coverBackground?: string;

  sections: {
    heading?: string;
    groups?: {
      label: string;
      teams?: { name?: string; logo_url?: string }[];
      tables: {
        head?: RowInput[];
        body?: RowInput[];
        additionalOptions?: Partial<UserOptions>;
      }[];
    }[];
  }[];
}) {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "pt",
    format: "A4",
    putOnlyUsedFonts: true,
  });

  createFirstPage(doc, {
    title: options.title,
    description: options.description,
    date: options.date,
    coverBackground: options.coverBackground,
  });

  for (const section of options.sections) {
    const groups = section.groups || [];

    for (const group of groups) {
      doc.addPage();

      doc.setFont("RedHatDisplay-Bold", "normal", "bold");
      doc.setFontSize(16);
      doc.setTextColor("#1A1C1E");
      doc.text(group.label, 20, 89 + 16);

      const tableHeadRowStartY = 134;
      createTeamsTableRow(doc, {
        startY: tableHeadRowStartY,
        teams: group.teams,
      });

      for (const [tableIndex, table] of group.tables.entries()) {
        const tableStartY = tableIndex === 0 ? 186 : 141;

        // @ts-expect-error `lastAutoTable` should exist on `doc` since it was patched by "auto-table" plugin
        const finalY = doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 16 : 0;
        const startY = finalY || tableStartY;
        const marginTop = 141;

        createSimpleTable(doc, {
          additionalOptions: table.additionalOptions,
          pageHeading: section.heading,
          startY,
          marginTop,
          head: table.head,
          body: table.body,
          willDrawPage(hookData) {
            if (hookData.pageNumber > 1) {
              createTeamsTableRow(doc, { startY: 89, teams: group.teams });
              hookData.settings.margin.top = hookData.doc.lastAutoTable.finalY
                ? hookData.doc.lastAutoTable.finalY + 16
                : 141;
            }
          },
        });
      }
    }
  }

  const currentDate = formatDate(new Date(), "MMMM DD, YYYY");
  const defaultFileName = `Report - ${currentDate}`;
  const filenameWithExtension = options.fileName ? `${options.fileName}.pdf` : `${defaultFileName}.pdf`;

  doc.save(filenameWithExtension);
}
