import type { components } from "#build/types/nuxt-open-fetch/atlit";
import type {
  IOrganization,
  IOrganizationPayload,
  IRequestPayloadOrganization,
  IRequestPayloadCreateOrganization,
} from "@/types";

export default function formatOrganization(organization: components["schemas"]["Organization"] & {
  meta?: components["schemas"]["MetaObject"];
}): IOrganization {
  return {
    id: organization.id,
    name: organization.attributes.name,
    name_short: organization.attributes.shortName,
    logo_url: organization.attributes.logo,
    organization_type: organization.attributes.type,
    location: {
      country: organization.attributes.location?.country,
      state: organization.attributes.location?.state,
      city: organization.attributes.location?.city,
    },
    external: organization.attributes.external,
    permissions: {
      canRead: organization.meta?.permissions?.includes("read") || false,
      canWrite: organization.meta?.permissions?.includes("write") || false,
      canInviteAdmin: organization.meta?.permissions?.includes("members:invite:admin") || false,
      canRemoveAdmin: organization.meta?.permissions?.includes("members:remove:admin") || false,
      canInviteTeam: organization.meta?.permissions?.includes("team:invite") || false,
      canRemoveTeam: organization.meta?.permissions?.includes("team:remove") || false,
      canDelete: organization.meta?.permissions?.includes("delete") || false,
    },
    isDisabledSharingEvents: organization.attributes?.rules?.sharing?.type?.includes("private") || false,
  };
}

export function formatOrganizationCreatePayload(payload: IOrganizationPayload): IRequestPayloadCreateOrganization {
  return {
    name: payload.name,
    shortName: payload.name_short || undefined,
    logo: payload.logo_file_key || undefined,
    type: payload.organization_type,
    location: {
      // @ts-expect-error country is optional
      country: payload.location?.country || undefined,
      state: payload.location?.state || undefined,
      // @ts-expect-error city is optional
      city: payload.location?.city || undefined,
    },
    rules: {
      sharing: {
        type: payload.isDisabledSharingEvents ? "private" : "public",
      },
    },
  };
}

export function formatOrganizationPayload(
  organization: Partial<IOrganizationPayload>,
): Partial<IRequestPayloadOrganization> {
  const payload: Partial<IRequestPayloadOrganization> = {
    name: organization.name,
    shortName: organization.name_short,
    logo: organization.logo_file_key,
    // @ts-expect-error Should be updated on Atlit BE swagger
    type: organization.organization_type,
  };

  if (organization.location) {
    payload.location = {
      country: organization.location?.country,
      state: organization.location?.state || null,
      city: organization.location?.city,
    };
  }

  if (organization.isDisabledSharingEvents !== undefined) {
    payload.rules = {
      sharing: {
        type: organization.isDisabledSharingEvents ? "private" : "public",
      },
    };
  }

  return payload;
}
