<script lang="ts" setup>
import { EventModalShare } from "#components";
import type { PxlIconName } from "@/components/U/Icon";
import { EVENT_STATUS, EVENT_TYPES, PLAY_BASED_GAMES } from "@/constants";
import type { IEvent } from "@/types";

const { t, locale } = useI18n();
const user = useUser();
const userProfile = useUserProfile();
const toast = useToast();
const modal = useModal();
const route = useRoute();
const segment = useSegment();
const localePath = useLocalePath();
const { copy: clipboardCopy, isSupported: isCopySupported } = useClipboard();
const {
  state: event,
  error,
  isLoading,
} = useAsyncState(
  () => getCachedEventById(route.params.eventId as string, { accessToken: route.query.accessToken as string }),
  null,
  {
    delay: 250,
    onSuccess: (event) => {
      if (event) loadEventRelatedTeam(0, event.teamId);
      return event;
    },
  },
);
const { isLoading: isExportingStatsToPdf, execute: exportStatsToPdf } = useAsyncState(
  () => generateStatsReportByEventId(event.value!.id, { locale: locale.value, t }),
  null,
  { onError, immediate: false },
);
const isSuperAdmin = computed(() => user.value?.hasSuperAdminRole);
const canCopyCMSId = computed(() => !!event.value?.external?.abe && isSuperAdmin.value);
const canCopyVSId = computed(() => !!event.value?.external?.vidswap && isSuperAdmin.value);

const isAlreadySentForBreakdown = ref(false);
const isLiveOrUpcoming = computed(
  () => event.value?.status === EVENT_STATUS.LIVE || event.value?.status === EVENT_STATUS.UPCOMING,
);
const isB2CAdmin = computed(() => !!userProfile.value);
const isSendForBreakdownAvailable = computed(() => {
  if (
    !event.value
    || !team.value
    || !user.value
    || user.value.hasB2BAccount
    || !isB2CAdmin.value
    || isLiveOrUpcoming.value
  )
    return false;

  return event.value.breakdowns.status === null && !isAlreadySentForBreakdown.value;
});

const canShowAddGameInfoPopup = ref(true);

const isAddGameInfoPopupShown = computed(() => {
  return (
    isFeatureEnabled("enable_event_add_game_info")
    && canShowAddGameInfoPopup.value
    && (!event.value?.game_info.team2_is_official || !event.value?.game_info.team1_is_official)
  );
});
const isAddGameInfoDisabled = computed(() => PLAY_BASED_GAMES.includes(event.value?.sport_type ?? "") || ![EVENT_TYPES.GAME, EVENT_TYPES.PRACTICE].includes(event.value?.game_info?.type ?? ""));

const { state: team, execute: loadEventRelatedTeam } = useAsyncState(
  (teamId: string) => getCachedUserTeamById(teamId, { accessToken: route.query.accessToken as string }),
  null,
  { immediate: false },
);

const tabs = computed(() => {
  const base = `/events/${route.params.eventId}`;

  return [
    { label: t("navigation.label_overview"), to: localePath({ path: `${base}/`, query: route.query }) },
    {
      label: t("navigation.label_videos_clips"),
      to: localePath({ path: `${base}/clips`, query: route.query }),
      active: route.path.includes("/clips"),
    },
    {
      label: t("navigation.label_videos_players"),
      to: localePath({ path: `${base}/athlete-stats`, query: route.query }),
      disabled: !isFeatureEnabled("enable_event_athlete_stats_tab"),
      hidden: !event.value?.permissions.availableStats,
    },
    {
      label: t("navigation.label_videos_stats"),
      to: localePath({ path: `${base}/team-stats`, query: route.query }),
      disabled: !isFeatureEnabled("enable_event_team_stats_tab"),
      hidden: !event.value?.permissions.availableStats,
    },
    {
      key: "charts",
      label: t("navigation.label_videos_charts"),
      icon: "external-link" as PxlIconName,
      onClick: () => {
        segment.track("Match Overview Charts Opened", formatTrackEvent(event.value as IEvent));

        getMatchChartsUrl(route.params.eventId as string)
          .then(url => window.open(url))
          .catch(onError);
      },
      disabled: isLiveOrUpcoming.value || !user.value || !event.value?.permissions.canOpenChartsVS,
    },
  ].filter(t => !t.hidden);
});

const pageTitle = computed(() => {
  if (isLoading.value) return t("labels.loading");
  if (!event.value) return t("pages.events.event_unknown");

  return `${event.value.game_info.team1_name} vs ${event.value.game_info.team2_name}`;
});
const breadcrumbs = computed(() => [
  { label: t("navigation.label_videos"), to: "/events/" },
  { label: pageTitle.value },
]);

async function copyCMSIdToClipboard() {
  const id = event.value?.external?.abe;

  if (!id) {
    toast.error(t("errors.cms_id_not_found"));
    return;
  }

  await clipboardCopy(id);

  toast.success(t("labels.copied_to_clipboard"));
}

async function copyVSIdToClipboard() {
  const id = String(event.value?.external?.vidswap);

  if (!id) {
    toast.error(t("errors.vs_id_not_found"));
    return;
  }

  await clipboardCopy(id);

  toast.success(t("labels.copied_to_clipboard"));
}

function onError(err: unknown) {
  toast.error(parseErrorMessage(err, { t }));
}

useHead({ title: pageTitle });
definePageMeta({ allow_guest: true });
</script>

<template>
  <AppLayout :breadcrumbs="user ? breadcrumbs : []">
    <RError
      v-if="error"
      context="event"
    />
    <div
      v-else-if="isLoading"
      class="skeletonLoading dark:skeletonLoadingDark h-[138px] rounded"
    />
    <div v-else-if="event">
      <div class="relative -mx-4 rounded-bl-none bg-white dark:bg-neutral-dark-700 md:mx-0 md:rounded">
        <EventScoreboard
          class="border-b border-b-neutral-light-200 dark:border-neutral-dark-900"
          :event="event"
        />

        <span
          v-if="event.status === EVENT_STATUS.LIVE"
          class="pxl-badge absolute left-4 top-4 border-none bg-red-500 text-white"
        >
          {{ t("labels.live") }}
        </span>

        <div class="absolute right-4 top-3 flex items-center gap-4">
          <UButton
            v-if="event.permissions.canExportStatsPDF"
            v-tooltip="t('labels.export_event_stats_pdf_report')"
            variant="link-secondary"
            icon="export-pdf"
            :loading="isExportingStatsToPdf"
            @click="exportStatsToPdf"
          />
          <UButton
            v-if="event.permissions.canShare"
            v-tooltip="t('labels.share')"
            variant="link-secondary"
            icon="share"
            @click="modal.open(EventModalShare, { video: event })"
          />
          <UMenu>
            <UList>
              <MenuItemVideoBookmark :video="event" />
              <MenuItemEventMatchOverview :event="event" />
              <MenuItemEventEditLineup :event="event" />
              <MenuItemEventOpenAdvancedEditor :event="event" />
              <MenuItemsEventRequestVideoExport :event="event" />
              <MenuItemsVideoDownload :video="event" />
              <UListItem
                v-if="
                  isFeatureEnabled('enable_event_add_game_info')
                    && (!event.game_info.team1_is_official || !event.game_info.team2_is_official)
                "
                icon="matches"
                :text="t('pages.events.event_cart_menu_add_game_info')"
                :to="!isAddGameInfoDisabled ? { path: `/events/edit/${event.id}` } : undefined"
                :disabled="isAddGameInfoDisabled"
              />
              <UListItem
                v-if="canCopyCMSId"
                icon="copy"
                :text="t('pages.events.copy_cms_id')"
                :disabled="!isCopySupported"
                @click="copyCMSIdToClipboard"
              />
              <UListItem
                v-if="canCopyVSId"
                icon="copy"
                :text="t('pages.events.copy_vs_id')"
                :disabled="!isCopySupported"
                @click="copyVSIdToClipboard"
              />
              <MenuItemVideoDelete
                :video="event"
                @deleted="navigateTo('/events/')"
              />
            </UList>
          </UMenu>
        </div>

        <div
          v-if="event.status !== EVENT_STATUS.UPCOMING"
          class="pt-4"
        >
          <RTabs :tabs="tabs" />
        </div>
      </div>

      <UInfo
        v-if="isAddGameInfoPopupShown"
        color="yellow"
        class="mt-6"
        closable
        @close="canShowAddGameInfoPopup = false"
      >
        <i18n-t
          keypath="pages.events.event_add_game_info_popup_text"
          tag="p"
        >
          <template #link>
            <UButton
              class="leading-[1.188rem] underline"
              variant="link-primary"
              :to="!isAddGameInfoDisabled ? { path: `/events/edit/${event.id}` } : undefined"
              :label="t('labels.here')"
              :disabled="isAddGameInfoDisabled"
            />
          </template>
        </i18n-t>
      </UInfo>

      <BreakdownsSendBreakdownBanner
        v-if="isSendForBreakdownAvailable"
        :event="event"
        :team="team!"
        @breakdown-submit-success="isAlreadySentForBreakdown = true"
      />

      <div class="mt-6">
        <NuxtPage
          :event="event"
          keepalive
        />
      </div>
    </div>
    <RError v-else />
  </AppLayout>
</template>
