<script setup lang="ts">
import type { Dropdown as VDropdown } from "floating-vue";
import { vInfiniteScroll } from "@vueuse/components";
import get from "lodash-es/get";
import type { ComputedRef } from "#imports";
import type { PxlIcon } from "@/components/U/Icon";
import type { inputSizes, inputVariants } from "@/components/U/Input";

type VDropdownPlacement = InstanceType<typeof VDropdown>["$props"]["placement"];

// TODO: Move into separate file when such feature will be supported
interface ICommonInputProps {
  name?: string;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  icon?: PxlIcon;
  leadingIcon?: PxlIcon;
  trailingIcon?: PxlIcon;
  trailing?: boolean;
  leading?: boolean;
  hasError?: boolean;
  size?: keyof typeof inputSizes;
  variant?: keyof typeof inputVariants;
}

interface SelectMenuOption {
  [key: string]: any;
  disabled?: boolean;
  icon?: PxlIcon;
  children?: SelectMenuOption[];
  image?: {
    src?: string;
    alt?: string;
  };
}

type InputValue = Date | string[] | string | number | null;

export interface AsyncOptionsLoadParams {
  q?: string;
  offset: number;
  limit?: number;
}

interface SelectMenuProps extends ICommonInputProps {
  modelValue?: InputValue;
  options: unknown[] | ((params: AsyncOptionsLoadParams) => Promise<unknown[]>) | ((params: AsyncOptionsLoadParams) => Promise<{ data: unknown[]; total: number }>);

  valueAttribute?: string;
  optionAttribute?: string;

  limit?: number;
  asyncSearch?: boolean;
  searchDelay?: number;
  searchable?: boolean;
  nullable?: boolean;
  multiple?: boolean;

  showPlaceholderOption?: boolean;
  showSelectedOptionIcon?: boolean;
  menuPlacement?: VDropdownPlacement;
}

const emit = defineEmits(["update:modelValue", "focus", "blur"]);
const props = withDefaults(
  defineProps<SelectMenuProps>(),
  {
    size: "lg",
    variant: "default",
    modelValue: "",
    options: () => [],
    limit: 20,
    searchDelay: 500,
    optionAttribute: "label",
    valueAttribute: "value",
    menuPlacement: "bottom-start",
  },
);
const { t } = useI18n();
const { emitFormChange, emitFormBlur, formGroup } = useFormGroup();
const inputId = computed(() => formGroup?.name.value || props.name);
const hasFocus = ref(false);
const searchValue = ref("");
const totalOptions = ref(0);

const { state: asyncOptions, isLoading: isLoadingAsyncOptions, error: isAsyncOptionsError, execute: executeAsyncOptionsLoad } = useAsyncState(
  (params: AsyncOptionsLoadParams) => onAsyncOptionsLoad(params),
  [],
  { resetOnExecute: false, immediate: false },
);
const isLoading = computed(() => props.loading);
const isDisabled = computed(() => isLoadingAsyncOptions.value || props.loading || props.disabled || !!formGroup?.disabled?.value);
const isAllOptionsSelected = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return filteredOptions.value.every(option => isOptionSelected(option));
  }

  return !!props.modelValue;
});
const isAnyOptionSelected = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return filteredOptions.value.some(option => isOptionSelected(option));
  }

  return !!props.modelValue;
});

const guessOptionValue = (option: any) => get(option, props.valueAttribute, get(option, props.optionAttribute));
const guessOptionText = (option: any) => get(option, props.optionAttribute, get(option, props.valueAttribute));

const normalizedOptions = computed(() => {
  if (Array.isArray(props.options)) {
    return props.options.map(option => normalizeOption(option));
  }

  return asyncOptions.value;
});
const normalizedOptionsWithPlaceholder: ComputedRef<SelectMenuOption[]> = computed(() => {
  if (!props.placeholder || !props.showPlaceholderOption) return normalizedOptions.value;

  return [
    { [props.valueAttribute]: "", [props.optionAttribute]: props.placeholder, disabled: !props.nullable },
    ...normalizedOptions.value,
  ];
});

const filteredOptions: ComputedRef<SelectMenuOption[]> = computed(() => {
  if (searchValue.value) {
    if (props.asyncSearch) return asyncOptions.value;
    return useSearchInArray(normalizedOptions.value, { q: searchValue.value, key: props.optionAttribute });
  }

  return normalizedOptionsWithPlaceholder.value;
});

const normalizedSelectedOption = computed<null | SelectMenuOption | SelectMenuOption[]>(() => {
  if (Array.isArray(props.modelValue)) {
    const normalizeModelValues = props.modelValue.map(normalizeOption);

    return normalizeModelValues.map((value) => {
      const foundOption = normalizedOptionsWithPlaceholder.value.find(option => option[props.valueAttribute] === value[props.valueAttribute]);

      if (!foundOption) {
        value[props.optionAttribute] = "-";
        return value;
      }

      return foundOption;
    });
  }

  const normalizeModelValue = normalizeOption(props.modelValue);
  return normalizedOptionsWithPlaceholder.value.find(option => option[props.valueAttribute] === normalizeModelValue[props.valueAttribute]) || null;
});
const normalizedSelectedOptionIcon = computed<null | SelectMenuOption["icon"]>(() => {
  if (Array.isArray(normalizedSelectedOption.value) || !normalizedSelectedOption.value) {
    return null;
  }

  return normalizedSelectedOption.value.icon;
});
const normalizedLabel = computed<string>(() => {
  const selectedOption = normalizedSelectedOption.value;

  if (Array.isArray(selectedOption)) {
    if (selectedOption.length >= 1)
      return `${selectedOption.length} options selected`;
    return "";
  }

  return selectedOption ? selectedOption[props.optionAttribute] : "";
});
const normalizedValue = computed<string | string[]>(() => {
  const selectedOption = normalizedSelectedOption.value;

  if (Array.isArray(selectedOption)) {
    return selectedOption.map(option => option[props.valueAttribute]);
  }

  return selectedOption ? selectedOption[props.valueAttribute] : "";
});

function onBlur(event: FocusEvent) {
  hasFocus.value = false;
  emitFormBlur();
  emit("blur", event);
}

function onFocus(event: FocusEvent) {
  hasFocus.value = true;
  emit("focus", event);
}

function onOptionSelect(option: SelectMenuOption) {
  const optionValue = guessOptionValue(option);

  if (props.multiple) {
    if (isOptionSelected(option)) {
      if (Array.isArray(props.modelValue)) {
        emit("update:modelValue", props.modelValue.filter(value => value !== optionValue));
      }
      else {
        emit("update:modelValue", []);
      }
    }
    else {
      if (Array.isArray(props.modelValue)) {
        emit("update:modelValue", [...props.modelValue, optionValue]);
      }
      else {
        emit("update:modelValue", [optionValue]);
      }
    }
  }
  else {
    if (props.nullable && isOptionSelected(option)) {
      emit("update:modelValue", "");
    }
    else {
      emit("update:modelValue", optionValue);
    }
  }

  emitFormChange();
}

function clearSelection() {
  if (props.modelValue) {
    emit("update:modelValue", []);
  }
  else {
    emit("update:modelValue", "");
  }

  emitFormChange();
}

function normalizeOption(option: any) {
  if (["string", "number", "boolean", "undefined", "bigint", "symbol", "function"].includes(typeof option)) {
    return {
      [props.valueAttribute]: option,
      [props.optionAttribute]: option,
    };
  }

  return {
    ...option,
    [props.valueAttribute]: guessOptionValue(option),
    [props.optionAttribute]: guessOptionText(option),
  };
}

function isOptionSelected(option: SelectMenuOption): boolean {
  const selectedValue = normalizedValue.value;
  if (Array.isArray(selectedValue)) {
    return !!selectedValue.find(value => option[props.valueAttribute] === value);
  }

  return option[props.valueAttribute] === selectedValue;
}

function handleAsyncResponse(res: unknown[] | { data: unknown[]; total: number }): SelectMenuOption[] {
  if (Array.isArray(res)) {
    return res.map(option => normalizeOption(option));
  }

  totalOptions.value = res.total;
  return res.data.map(option => normalizeOption(option));
}

async function onAsyncOptionsLoad(options: AsyncOptionsLoadParams): Promise<SelectMenuOption[]> {
  if (typeof props.options !== "function") {
    console.error("Can't load async options since 'options' prop is not a function!");
    return Promise.resolve([]);
  }

  const q = options?.q;
  const offset = options?.offset;
  const limit = options?.limit || props.limit;
  const params: AsyncOptionsLoadParams = { q, offset, limit };

  return props.options(params).then(handleAsyncResponse).then((items) => {
    // If the offset is positive then we're assuming it's a pagination call and merging previous items with new
    if (offset > 0) {
      return asyncOptions.value.concat(items);
    }

    return items;
  });
}

function selectAllOptions() {
  if (!props.multiple) return;

  const value = filteredOptions.value.map(option => guessOptionValue(option));
  emit("update:modelValue", value);
}

function onLoadMoreOptions() {
  executeAsyncOptionsLoad(0, { offset: asyncOptions.value.length });
}

function canLoadMore(): boolean {
  if (typeof props.options === "function") {
    return totalOptions.value > asyncOptions.value.length;
  }

  return false;
}

defineExpose({ executeAsyncOptionsLoad });

onMounted(() => typeof props.options === "function" && executeAsyncOptionsLoad(0, { offset: 0 }));
// // @ts-expect-error `useDebounceFn` doesn't use generics, so `q` is set to any type and because of it we have a TS type conflict =(
watch(searchValue, useDebounceFn(q => props.asyncSearch && executeAsyncOptionsLoad(0, { q, offset: 0 }), props.searchDelay));
</script>

<template>
  <UMenu
    :class="props.variant === 'inline' ? '' : 'w-full'"
    :dropdown="{ placement: props.menuPlacement, autoSize: props.variant !== 'inline', strategy: 'absolute' }"
    @show="onFocus"
    @hide="onBlur"
  >
    <template #trigger="{ shown, toggle }">
      <UInputControl
        :loading="isLoading"
        :disabled="props.disabled"
        :icon="props.icon"
        :leading-icon="(props.showSelectedOptionIcon && normalizedSelectedOptionIcon) || props.leadingIcon"
        :trailing-icon="props.trailingIcon || { name: 'chevron-down-small', class: ['cursor-pointer duration-200 transition transform', props.variant === 'blurry' ? 'text-current' : 'text-black dark:text-white', shown && 'rotate-180'] }"
        :trailing="props.trailing"
        :leading="props.leading"
        :has-error="props.hasError"
        :has-focus="hasFocus"
        :size="props.size"
        :variant="props.variant"
      >
        <template
          v-if="$slots.leading"
          #leading="{ disabled: slotDisabled, loading: slotLoading }"
        >
          <slot
            name="leading"
            :disabled="slotDisabled"
            :loading="slotLoading"
          />
        </template>

        <template
          v-if="$slots.trailing"
          #trailing="{ disabled: slotDisabled, loading: slotLoading }"
        >
          <slot
            name="trailing"
            :disabled="slotDisabled"
            :loading="slotLoading"
          />
        </template>

        <button
          :id="inputId"
          class="u-input-field text-left"
          type="button"
          role="combobox"
          :aria-expanded="shown"
          :name="props.name || formGroup?.name.value"
          :placeholder="props.placeholder"
          :disabled="isDisabled"
          @click="toggle"
          @focus="onFocus"
          @blur="onBlur"
        >
          <span v-if="normalizedLabel">{{ normalizedLabel }}</span>
          <span
            v-else-if="props.placeholder"
            :class="{ 'text-neutral-light-800 dark:text-neutral-dark-200': props.variant === 'default' }"
          >{{ props.placeholder }}</span>
          <span v-else>-</span>
        </button>
      </UInputControl>
    </template>

    <div>
      <div
        v-if="props.searchable"
        class="flex gap-4 bg-white p-4 dark:bg-neutral-dark-700"
      >
        <UCheckbox
          v-if="props.multiple"
          :model-value="isAllOptionsSelected"
          name="select-all-options"
          :indeterminate="isAnyOptionSelected && !isAllOptionsSelected"
          :disabled="isDisabled || !filteredOptions.length"
          @update:model-value="isAllOptionsSelected ? clearSelection() : selectAllOptions()"
        />
        <UInput
          v-model="searchValue"
          :loading="isLoadingAsyncOptions"
          size="md"
          class="flex-1"
          :placeholder="t('labels.search')"
          icon="search"
          variant="filled"
          autofocus
        />
      </div>

      <UDivider
        v-if="props.searchable"
        lighter
      />

      <UList
        v-infinite-scroll="[onLoadMoreOptions, { canLoadMore }]"
        v-auto-animate
        class="max-h-64 !min-w-full overflow-auto"
        :class="props.variant === 'inline' && 'sm:max-w-72'"
      >
        <UListItem
          v-if="isAsyncOptionsError"
          size="lg"
          :has-hover="false"
          :on-click-close-menu="false"
          text="Error occurred during the search"
        />
        <UListItem
          v-for="(option, index) in filteredOptions"
          v-else-if="filteredOptions.length"
          :key="`${option[props.valueAttribute]}-${index}`"
          size="lg"
          :value="option[props.valueAttribute]"
          :aria-selected="isOptionSelected(option)"
          :icon="option.icon"
          :disabled="option.disabled"
          :text="String(option[props.optionAttribute])"
          :trailing-icon="props.multiple ? null : { name: isOptionSelected(option) ? 'check' : 'empty', class: 'text-blue-500' }"
          :divider="(filteredOptions.length - 1) !== index"
          :on-click="() => onOptionSelect(option)"
          :image="option.image"
        >
          <div
            v-if="props.multiple"
            class="flex flex-1 items-center gap-4"
          >
            <UCheckbox
              :model-value="isOptionSelected(option)"
              :name="`select-option-${index}-${option[props.valueAttribute]}`"
              :disabled="option.disabled"
              @update:model-value="() => onOptionSelect(option)"
              @click.stop
            />
            <span>{{ String(option[props.optionAttribute]) }}</span>
          </div>
        </UListItem>
        <UListItem
          v-else
          size="lg"
          :has-hover="false"
          :on-click-close-menu="false"
          text="The list is empty"
        />
        <UListItem
          v-if="isLoadingAsyncOptions"
          size="lg"
          :has-hover="false"
          :on-click-close-menu="false"
          text="Loading..."
        />
      </UList>
    </div>
  </UMenu>
</template>
