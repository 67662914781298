import type { TVideoType } from "@/types";

type TVideoLogType = "hd" | "clip" | "highlight" | "player-highlight" | "mbe-event";

export function logVideoView(id: string, type: TVideoType, options?: { accessToken?: string }): void {
  const body: { id: string; type: TVideoLogType } = {
    id,
    type: type === "event" ? "hd" : type === "autohighlight" ? "highlight" : type,
  };

  useAPI("/api/v1/videos/views", {
    method: "POST",
    body,
    ...(options?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${options.accessToken}` } }),
  }).catch(() => {});
}
