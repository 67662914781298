import type { PxlIconName } from "#build/pxl-icons";

interface NavLink {
  type: "link" | "separator";
  label?: string;
  iconName?: PxlIconName;
  to?: string;
  hidden?: boolean;
}

export default function useNavbar() {
  const { t } = useI18n();
  const appConfig = useAppConfig();
  const logoPath = computed(() => (appConfig.useMVP ? "/events/" : "/"));
  const localePath = useLocalePath();
  const user = useUser();
  // Note: currently we don't support restrictions by users teams(e.g. their sports)
  // Also such feature flag should be probably somewhere on the BE so this solution is temporary and should be removed
  const isAthletesEnabled = isFeatureEnabled("enable_athletes_index_page") && (user.value?.hasSuperAdminRole || user.value?.teamsSports.includes("basketball"));
  const links = computed<NavLink[]>(() => [
    {
      type: "link",
      label: t("navigation.label_my_organization"),
      iconName: "organization",
      to: localePath("/organizations/"),
      hidden: user.value?.hasSuperAdminRole ? false : !user.value?.hasOrganizationsAccess,
    },
    {
      type: "link",
      label: t("navigation.label_my_profile"),
      iconName: "profile",
      to: localePath("/user/profile/"),
      hidden: appConfig.useMVP,
    },
    {
      type: "link",
      label: t("navigation.label_my_highlights"),
      iconName: "my-highlights",
      to: localePath("/user/content/"),
    },
    { type: "separator" },
    { type: "link", label: t("navigation.label_videos"), iconName: "matches", to: localePath("/events/") },
    {
      type: "link",
      label: t("navigation.label_practices"),
      iconName: "practices",
      to: localePath("/practices/"),
      hidden: appConfig.useMVP,
    },
    {
      type: "link",
      label: t("navigation.label_highlights"),
      iconName: "highlights",
      to: localePath("/highlights/"),
      hidden: appConfig.useMVP,
    },
    { type: "separator", hidden: !user.value?.hasSuperAdminRole && !isAthletesEnabled },
    { type: "link", label: t("navigation.label_teams"), iconName: "teams", to: localePath("/teams/") },
    {
      type: "link",
      label: t("navigation.label_athletes"),
      iconName: "athletes",
      to: localePath("/athletes/"),
      hidden: !isAthletesEnabled,
    },
    {
      type: "link",
      label: t("navigation.label_leagues"),
      iconName: "leagues",
      to: localePath("/leagues/"),
      hidden: user.value?.hasSuperAdminRole ? false : !user.value?.hasLeaguesAccess,
    },
  ]);

  return { logoPath: localePath(logoPath.value), links };
}
