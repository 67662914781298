import type { ISelectOption } from "@/components/R/Select/Select.vue";
import { formatClip } from "@/helpers/format-event";
import type {
  IClip,
  IEvent,
  IPlaylist,
  IPlaylistItem,
  IRequestPayloadTag,
  IResponseClip,
  IResponseEventClipOptions,
  ITag,
} from "@/types";

export const getCachedUserClips = useMemoize(
  (opts: { limit: number; offset: number }): Promise<{ data: IClip[]; total: number }> => getUserClips(opts),
);

export function getUserClips(opts: { limit: number; offset: number }): Promise<{ data: IClip[]; total: number }> {
  return useAPI<{ data: IResponseClip[]; meta: { total: number } }>("/api/v1/users/me/clips", {
    query: {
      limit: opts.limit,
      offset: opts.offset,
      relationships: "favorite",
    },
  }).then(response => ({ data: response.data.map(formatClip), total: response.meta.total }));
}

export function getUserClipById(clipId: string, options?: { accessToken?: string }): Promise<IClip> {
  return useAPI<IResponseClip>(`/api/v1/users/me/clips/${clipId}`, {
    ...(options?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${options.accessToken}` } }),
    query: {
      relationships: "favorite",
    },
  }).then(formatClip);
}

export function getEventUserClipsById(
  eventId: string,
  opts: { limit: number; offset: number; accessToken?: string },
): Promise<{ data: IClip[]; total: number }> {
  return useAPI<{ data: IResponseClip[]; meta: { total: number } }>("/api/v1/users/me/clips", {
    query: {
      eventId,
      limit: opts.limit,
      offset: opts.offset,
      relationships: "favorite",
    },
    ...(opts?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${opts.accessToken}` } }),
  }).then(response => ({ data: response.data.map(formatClip), total: response.meta.total }));
}

export function deleteUserClipById(clipId: string): Promise<void> {
  return useAPI(`/api/v1/users/me/clips/${clipId}`, { method: "DELETE" }).then(() => {
    getCachedUserClips.clear();
  });
}

export function createEventClipFromTag(event: IEvent, tag: IRequestPayloadTag): Promise<void> {
  const body = {
    eventId: event.id,
    name: tag.name,
    startTime: tag.startTime,
    endTime: tag.endTime,
    url: Array.from(event.urls.values()).find(item => item?.url)?.url,
  };

  return useAPI("/api/v1/users/me/clips", { method: "POST", body });
}

export function createEventClipFromTagsWithOptions(
  event: IEvent,
  options: {
    name: string;
    soundtrack: string;
    transition: "crosswarp" | null;
    sourceAudioVolume: 0 | 1;
    tags: ITag[] | IPlaylistItem[];
  },
): Promise<void> {
  const body = {
    eventId: event.id,
    name: options.name,
    url: Array.from(event.urls.values()).find(item => item?.url)?.url,
    tagIds: options.tags.map(t => t.id),
    branding: "pixellot",
    soundtrack: options.soundtrack,
    sourceAudioVolume: options.sourceAudioVolume,
    transition: options.transition,
  };

  return useAPI("/api/v1/users/me/clips/tags", { method: "POST", body });
}

export function createEventClipFromPlaylistWithOptions(
  playlist: IPlaylist,
  options: {
    name: string;
    soundtrack: string;
    transition: "crosswarp" | null;
    sourceAudioVolume: 0 | 1;
    tags: IPlaylistItem[] | ITag[];
    playlist?: IPlaylist;
  },
): Promise<void> {
  const body = {
    playlistId: playlist.id,
    itemIds: options.tags.map(t => t.id),
    name: options.name,
    branding: "pixellot",
    soundtrack: options.soundtrack,
    sourceAudioVolume: options.sourceAudioVolume,
    transition: options.transition,
  };

  return useAPI("/api/v1/users/me/clips/playlists", { method: "POST", body });
}

export function createEventPlayerHighlightFromTagsWithOptions(
  event: IEvent,
  options: {
    soundtrack: string;
    teamColor: string;
    playerNumber: string;
    transition: "crossfade" | null;
    sourceAudioVolume: 0 | 1;
    tags: ITag[] | IPlaylistItem[];
  },
): Promise<void> {
  const body = {
    eventId: event.id,
    url: Array.from(event.urls.values()).find(item => item?.url)?.url,
    team: {
      player: options.playerNumber,
      color: options.teamColor,
    },
    tagIds: options.tags.map(t => t.id),
    branding: "pixellot",
    soundtrack: options.soundtrack,
    sourceAudioVolume: options.sourceAudioVolume,
    transition: options.transition,
  };

  return useAPI("/api/v1/player-highlights", { method: "POST", body });
}

export function getEventClipMusicOptions(): Promise<ISelectOption[]> {
  const cachedOptions = useState<ISelectOption[]>("event-clip-music-options", () => []);

  // Return the url from cache if such
  if (cachedOptions.value.length) return Promise.resolve(cachedOptions.value);

  return useAPI<IResponseEventClipOptions>("/api/v1/users/me/clips/static").then((res) => {
    const data = Object.entries(res.data.attributes.soundtrack).map(([sKey, sVal]) => ({
      label: sKey,
      value: sVal.track,
    }));

    cachedOptions.value = data; // Storing the values in cache, so we won't need to send request again

    return data;
  });
}
