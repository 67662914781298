import { defaultFeatureFlags } from "@/constants/feature-flags";
import { formatFeatures, formatFeaturesConfig } from "@/helpers/format-features";
import { clone, isSame } from "@/helpers/object";
import type { FeatureFlag, FeatureFlagObject, FeatureFlagAdvancedValue } from "@/types/feature-flags";

export const FEATURE_FLAG_ADVANCED_VALUE: FeatureFlagAdvancedValue = {
  superAdmins: false,
  users: [],
};

export function formatFeatureName(feature: string) {
  return feature
    .replace("enable_", "")
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function loadFeatureFlagDetails(): Promise<
  Record<
    string,
    FeatureFlag["details"] & {
      title: string;
    }
  >
> {
  return $fetch("/json/feature-details.json");
}

async function loadFeatureFlags(): Promise<FeatureFlag[]> {
  const featuresData = await getFeatureFlags();
  const details = await loadFeatureFlagDetails();

  const features = Object.entries(featuresData).map(([feature, value]) => {
    const detailsForFeature = details[feature];
    const title = detailsForFeature?.title || formatFeatureName(feature);

    const item: FeatureFlag = {
      key: feature,
      label: title,
      enabled: value,
    };

    if (detailsForFeature) {
      item.details = {
        image: detailsForFeature.image,
        description: detailsForFeature.description,
      };
    }

    return item;
  });

  return features;
}

export default function useFeatureFlags() {
  const user = useUser();
  const isSuperAdmin = computed(() => user.value?.hasSuperAdminRole);
  const data = ref<null | FeatureFlag[]>(null);
  const initialData = ref<null | FeatureFlag[]>(null);
  const route = useRoute();
  const featurePageEnabled = !!isSuperAdmin.value && !!route.query.demo;

  const asyncParams = useAsyncState(loadFeatureFlags, null, {
    immediate: true,
    shallow: true,
    onSuccess: (response) => {
      if (!response) {
        throw new Error("Failed to request feature flags");
      }

      const defaultFeatures = formatFeatures(defaultFeatureFlags);

      data.value = formatFeaturesConfig(response, defaultFeatures);
      initialData.value = clone(response);
    },
  });
  const isDataChanged = computed(() => !isSame(initialData.value, data.value));

  async function save(payload: FeatureFlag[]) {
    const data = payload.reduce((acc, curr) => {
      acc[curr.key] = curr.enabled;
      return acc;
    }, {} as FeatureFlagObject);

    await updateFeatureFlags(data);

    initialData.value = payload;

    return payload;
  }

  return {
    ...asyncParams,
    data,
    initialData,
    isDataChanged,
    isPageEnabled: featurePageEnabled,
    save,
  };
}
