<script lang="ts" setup>
import type { ITag } from "@/types";
import { formatTime } from "@/helpers/format-time";

const props = defineProps<{
  tags?: ITag[];
}>();
const emit = defineEmits<{
  (event: "delete-tag" | "edit-tag", tag: ITag): void;
}>();
</script>

<template>
  <div
    class="relative z-40 flex max-h-32 flex-col gap-3 overflow-auto rounded bg-white p-3 text-left dark:bg-neutral-dark-700 sm:max-h-48 lg:max-h-96"
  >
    <div
      v-for="tag in props.tags"
      :key="tag.id"
    >
      <div class="flex items-center justify-between gap-2">
        <div class="text-xs font-bold text-black dark:text-white">
          {{ tag.title }}
        </div>
        <div class="flex gap-2">
          <UButton
            icon="edit"
            variant="link-secondary"
            @click.stop="emit('edit-tag', tag)"
          />
          <UButton
            icon="delete"
            variant="link-danger"
            @click.stop="emit('delete-tag', tag)"
          />
        </div>
      </div>
      <div class="mt-2 flex items-center justify-between gap-2 text-xs font-medium">
        <div class="text-neutral-light-800 dark:text-neutral-dark-200">
          {{ formatTime(tag.startTime) }} - {{ formatTime(tag.endTime) }}
        </div>
        <div
          v-if="tag.playerNumber"
          class="size-4 overflow-hidden rounded-full bg-dark-blue-500 py-0.5 text-center leading-[15px] text-white"
        >
          {{ tag.playerNumber }}
        </div>
      </div>
    </div>
  </div>
</template>
