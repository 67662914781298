<script lang="ts" setup>
import type { PxlIcon } from "@/components/U/Icon";
import { iconBind } from "@/components/U/Icon";

export type ITabProp = string | ITab;
export interface ITab {
  key?: string;
  label: string;
  to?: string | null;
  icon?: PxlIcon;
  active?: boolean | null;
  disabled?: boolean | null;
  indicatorText?: string | null;
  indicatorType?: "danger" | "warning" | null;
  onClick?: (tab: ITabProp) => any;
}

const props = withDefaults(
  defineProps<{
    tabs?: ITabProp[];
    active?: string;
    filled?: boolean;
  }>(),
  {
    tabs: () => [],
  },
);
const emit = defineEmits(["update:active", "tabClick"]);

const tabs = computed<ITab[]>(() => (props.tabs || []).map((t, tIndex) => {
  if (typeof t === "string" || typeof t === "number") {
    return { label: t, key: t };
  }

  // Generate the 'unique' key if it's not present
  if (typeof t === "object" && !t.key) {
    t.key = t.label || String(tIndex);
  }

  return t;
}));

function onTabClick(tab: ITab) {
  if (tab.disabled) return;

  if (tab.onClick) {
    tab.onClick(tab);
  }

  emit("tabClick", tab);

  if (!tab.disabled) {
    emit("update:active", tab.key);
  }
}
</script>

<template>
  <ul
    class="pxl-tabs-list"
    :class="props.filled ? 'pxl-tabs-list-filled' : ''"
  >
    <li
      v-for="tab in tabs"
      :key="tab.label"
      class="whitespace-nowrap"
    >
      <span
        v-if="tab.disabled"
        href="#"
        class="pxl-tab disabled"
        :class="{ active: props.active === tab.key || tab.active }"
        @click="onTabClick(tab)"
      >
        <span class="relative">
          {{ tab.label }}
          <span
            v-if="tab.indicatorText"
            class="r-indicator"
            :class="`r-indicator--${tab.indicatorType || 'danger'}`"
          >
            {{ tab.indicatorText }}
          </span>
        </span>
        <UIcon
          v-if="tab.icon"
          v-bind="iconBind(tab.icon)"
        />
      </span>
      <NuxtLink
        v-else-if="tab.to"
        :to="tab.to"
        class="pxl-tab"
        exact-active-class="active"
        :class="{ active: tab.active }"
      >
        <span class="relative">
          {{ tab.label }}
          <span
            v-if="tab.indicatorText"
            class="r-indicator"
            :class="`r-indicator--${tab.indicatorType || 'danger'}`"
          >
            {{ tab.indicatorText }}
          </span>
        </span>
        <UIcon
          v-if="tab.icon"
          v-bind="iconBind(tab.icon)"
        />
      </NuxtLink>
      <a
        v-else
        href="#"
        class="pxl-tab"
        :class="{ active: props.active === tab.key || tab.active }"
        @click="onTabClick(tab)"
      >
        <span class="relative">
          {{ tab.label }}
          <span
            v-if="tab.indicatorText"
            class="r-indicator"
            :class="`r-indicator--${tab.indicatorType || 'danger'}`"
          >
            {{ tab.indicatorText }}
          </span>
        </span>
        <UIcon
          v-if="tab.icon"
          v-bind="iconBind(tab.icon)"
        />
      </a>
    </li>
  </ul>
</template>

<style lang="scss">
/* Tabs */
.pxl-tabs-list {
  @apply flex md:flex-wrap text-sm font-medium text-center text-neutral-light-800 dark:text-neutral-dark-200 overflow-x-auto snap-x;
}
.pxl-tabs-list.pxl-tabs-list-filled {
  @apply p-1.5 bg-neutral-light-200 dark:bg-neutral-dark-700 text-black dark:text-white rounded gap-1.5;
}
.pxl-tabs-list.pxl-tabs-list-filled .pxl-tab {
  @apply px-8 rounded border-none;
}
.pxl-tabs-list.pxl-tabs-list-filled .pxl-tab.active {
  @apply border-none bg-blue-500 text-white font-bold;
}
.pxl-tab {
  @apply relative inline-flex items-center gap-3 px-6 py-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 snap-center;
}
.pxl-tab.active {
  @apply text-blue-500 border-b-2 border-blue-500 dark:text-blue-500 dark:border-blue-500;
}
.pxl-tab.disabled {
  @apply opacity-50 cursor-not-allowed;
}

/* Indicator */
.r-indicator {
  @apply absolute z-10 -top-2 -right-3 h-3.5 min-w-[14px] px-1
  rounded-full text-[10px] leading-[14px] text-white;
}
.r-indicator.r-indicator--warning {
  @apply bg-[#F18F01] dark:bg-[#F18F01];
}
.r-indicator.r-indicator--danger {
  @apply bg-[#E13100] dark:bg-[#E13100];
}
</style>
