import type { IRequestPaginateParams } from "./events";
import type { IBasicVideo, IPlaylist, IResponseFavoriteVideos } from "@/types";
import { formatFavoriteVideo } from "@/helpers/format-event";
import { VIDEO_TYPE } from "@/constants";

type TFavoriteType = IBasicVideo["type"] | IPlaylist["source_type"];

function getParamFromFavoriteType(type?: TFavoriteType) {
  switch (type) {
    case VIDEO_TYPE.EVENT:
      return "events";
    case VIDEO_TYPE.CLIP:
      return "clips";
    case VIDEO_TYPE.GAME_HIGHLIGHT:
      return "auto-highlights";
    case VIDEO_TYPE.PLAYLIST:
      return "playlists";
    case VIDEO_TYPE.ATHLETE_HIGHLIGHT:
      return "player-highlights";
    default:
      return "";
  }
}

export const getCachedFavoriteVideos = useMemoize(
  (
    options: Partial<IRequestPaginateParams & { type: TFavoriteType }>,
  ): Promise<{ data: IBasicVideo[]; total: number }> => getFavoriteVideos(options),
);

export function getFavoriteVideos<T extends IBasicVideo>(
  options: Partial<IRequestPaginateParams & { type: TFavoriteType }>,
): Promise<{ data: T[]; total: number }> {
  const param = getParamFromFavoriteType(options.type);

  return useAPI<IResponseFavoriteVideos>(`/api/v1/users/me/favorites/${param}`, {
    query: {
      limit: options.limit,
      offset: options.offset,
      relationships: "favorite",
    },
  }).then(response => ({ data: response.data.map(formatFavoriteVideo) as T[], total: response.meta.total }));
}

function addFavoriteItem(
  targetId: string,
  options: { eventId?: string; type: TFavoriteType },
): Promise<{ id: string }> {
  const body = {
    ...(options.eventId && { event: options.eventId }),
    type: options.type,
    targetId: targetId,
  };

  return useAPI<{ id: string }>("/api/v1/users/me/favorites", { method: "POST", body });
}

function removeFavoriteItem(id: string): Promise<void> {
  return useAPI(`/api/v1/users/me/favorites/${id}`, { method: "DELETE" });
}

export function toggleFavoriteItem(item: IBasicVideo | IPlaylist) {
  function isPlaylist(item: any): item is IPlaylist {
    return item.source_type === "playlist";
  }

  const type: "Video" | "Playlist" | "Event" = isPlaylist(item)
    ? "Playlist"
    : item.type === VIDEO_TYPE.EVENT
      ? "Event"
      : "Video";
  const payload = isPlaylist(item) ? formatTrackPlaylist(item as IPlaylist) : formatTrackVideo(item);

  if (item.is_favorite) {
    useSegment().track(`${type} Unfavorited`, payload);

    return removeFavoriteItem(item.favoriteId!);
  }

  const options = {
    ...(!isPlaylist(item) && { eventId: item.eventId }),
    type: isPlaylist(item) ? ("playlist" as const) : item.type,
  };

  useSegment().track(`${type} Favorited`, payload);

  return addFavoriteItem(item.id, options).then((data) => {
    item.favoriteId = data.id;
  });
}
