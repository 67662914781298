<script setup lang="ts">
import type { IBasicVideo, IEvent, IPlaylist } from "@/types";
import type { PxlIcon } from "@/components/U/Icon";
import { UserModalAuthRequired } from "#components";
import { VIDEO_TYPE } from "@/constants";
import type { IPixellotPlayerSourceType } from "~~/modules/pixellot-sdk";

const props = defineProps<{
  video: IEvent | IBasicVideo;
  type: IPixellotPlayerSourceType;
}>();

const { t } = useI18n();
const user = useUser();
const modal = useModal();
const segment = useSegment();
const isEvent = (vid?: IPlaylist | IEvent | IBasicVideo | null): vid is IEvent => vid?.type === VIDEO_TYPE.EVENT;
const icon = computed<PxlIcon>(() => {
  if (props.type === "hd") {
    return {
      name: "download-hd",
      class: "text-neutral-light-700 dark:text-neutral-dark-200 transform translate-x-0.5 scale-125",
    };
  }
  else if (props.type === "pano") {
    return {
      name: "download-pano",
      class: "text-neutral-light-700 dark:text-neutral-dark-200 transform translate-x-0.5 scale-125",
    };
  }

  return "download";
});
const label = computed(() => {
  if (props.type === "hd") {
    return t("labels.download", { type: "HD" });
  }
  if (props.type === "pano") {
    return t("labels.download", { type: "Pano" });
  }

  // Everything else assumed as an angle type if not HD or Pano
  return t("labels.download", { type: `${props.type} angle` });
});
const mp4Url = computed(() => props.video.urls.get(props.type)?.mp4_url);

function onClick() {
  if (user.value) {
    window.open(mp4Url.value, "Download");

    segment.track("Download Video Started", formatTrackVideo(props.video));
  }
  else {
    modal.open(UserModalAuthRequired);
  }
}

const isDisabled = computed(() => {
  // If event was not exported yet - disable the button and show a tooltip with explanation
  if (isEvent(props.video) && !mp4Url.value) {
    return true;
  }

  return false;
});

const tooltip = computed(() => {
  if (isEvent(props.video) && isDisabled.value) {
    if (props.video.permissions.canExport) return t("labels.download_tooltip_text_for_league");
    return t("labels.download_tooltip_text");
  }

  return undefined;
});
</script>

<template>
  <UListItem
    :icon="icon"
    :text="label"
    :disabled="isDisabled"
    :on-click="onClick"
    :tooltip="tooltip"
  />
</template>
