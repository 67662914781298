import type { ModalState } from "@/composables/use-modal";

export default defineNuxtPlugin((nuxtApp) => {
  const modalState = shallowRef<ModalState>({
    component: "div",
    props: {},
  });

  nuxtApp.vueApp.provide(modalInjectionKey, modalState);
});
