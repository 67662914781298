<script setup lang="ts">
import { useVModel, useAsyncState } from "@vueuse/core";
import type { PxlIcon } from "@/components/U/Icon";
import { iconBind } from "@/components/U/Icon";
import { UButton } from "#components";

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    icon?: PxlIcon;
    heading?: string;
    description?: string;
    cancelLabel?: string;
    confirmLabel?: string;
    confirmFn?: (() => void) | (() => Promise<void>);
    cancelFn?: (() => void) | (() => Promise<void>);
    variant?: "confirm" | "delete";
  }>(),
  {
    icon: "check",
    heading: "Are you sure you want to confirm this?",
    description: "This action cannot be reversed.",
    cancelLabel: "Cancel",
    confirmLabel: "Confirm",
    variant: "confirm",
  },
);

const emit = defineEmits(["update:modelValue"]);
const isOpen = useVModel(props, "modelValue", emit);
const closeModal = () => (isOpen.value = false);
const confirmVariant = computed<InstanceType<typeof UButton>["$props"]["variant"]>(() => props.variant === "delete" ? "danger" : "primary");
const { isLoading, execute: onAsyncClick } = useAsyncState(
  (fn: (() => void) | (() => Promise<void>)) => Promise.resolve().then(fn),
  undefined,
  { immediate: false, onSuccess: closeModal, onError: closeModal },
);

function onActionClick(action: "confirm" | "cancel"): Promise<void> {
  if (action === "confirm" && props.confirmFn) return onAsyncClick(0, props.confirmFn);
  else if (action === "cancel" && props.cancelFn) return onAsyncClick(0, props.cancelFn);
  return onAsyncClick(0, Promise.resolve);
}
</script>

<template>
  <UModal v-model="isOpen">
    <div class="p-4">
      <div class="mb-1 text-end">
        <UButton
          title="Close"
          variant="link-secondary"
          icon="close"
          class="size-5"
          @click="closeModal()"
        />
      </div>

      <div class="flex flex-col gap-6 md:px-6 md:pb-6">
        <!-- ICON -->
        <div class="text-center">
          <div class="relative inline-grid size-16 place-items-center rounded-full border border-neutral-light-300 dark:border-neutral-dark-300">
            <UIcon v-bind="iconBind(props.icon, { class: 'h-6 w-6 text-neutral-light-800 dark:text-neutral-dark-200' })" />

            <div
              v-if="variant === 'delete'"
              class="absolute -right-2 bottom-0 grid size-6 place-items-center rounded-full bg-red-500"
            >
              <UIcon
                name="close"
                class="size-3 text-white"
              />
            </div>
          </div>
        </div>
        <!-- TEXT -->
        <div class="space-y-4 text-center text-black dark:text-white">
          <h1
            class="text-2xl font-bold"
            v-text="heading"
          />
          <p
            class="text-sm font-medium"
            v-text="description"
          />
        </div>
        <!-- BUTTONS -->
        <div class="flex gap-4">
          <UButton
            class="flex-1"
            size="lg"
            :label="cancelLabel"
            variant="secondary"
            @click="onActionClick('cancel')"
          />
          <UButton
            class="flex-1"
            size="lg"
            :label="confirmLabel"
            :loading="isLoading"
            :variant="confirmVariant"
            @click="onActionClick('confirm')"
          />
        </div>
      </div>
    </div>
  </UModal>
</template>
