import { defaultFeatureFlags } from "./constants/feature-flags";

export default defineAppConfig({
  useMVP: true,
  useMockData: false,
  debugPano: false,
  currentSeason: 2025,
  currentCrossSeason: 2024,
  availableSeasons: [2023, 2024, 2025],
  /**
   * Load features from the server.
   * If set to false, the app will use the default feature flags without API call and replacement of those from the server.
   */
  loadFeatures: true,
  features: defaultFeatureFlags,
});
