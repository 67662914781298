import type {
  ILeague,
  ILeaguePayload,
  IResponseClubs,
  IResponseLeague,
  IResponseLeagueClubs,
  IResponseLeagues,
  ITeam,
} from "@/types";
import type { components } from "#build/types/nuxt-open-fetch/atlit";
import formatTeam, { formatTeamsData } from "@/helpers/format-team";
import formatLeague, { formatLeagueCreatePayload, formatLeagueUpdatePayload } from "@/helpers/format-league";
import getSharedHeaders from "@/helpers/get-headers";

interface IRequestParams {
  q: string;
  limit: number;
  offset: number;
}

export const getCachedLeaguesList = useMemoize(
  (query?: Partial<IRequestParams>): Promise<{ data: ILeague[]; total: number }> => getLeaguesList(query),
);

export async function getLeaguesList(query?: Partial<IRequestParams>): Promise<{ data: ILeague[]; total: number }> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData)
    return $fetch<IResponseLeagues>("/mocks/data/leagues.json").then(res => ({
      data: res.data.map(formatLeague),
      total: res.meta.total,
    }));

  return useAPI<IResponseLeagues>("/api/v1/leagues", { query }).then((res) => {
    const user = useUser();

    if (user.value && !query) {
      user.value.hasLeaguesAccess = res.data.length > 0;
    }

    return {
      data: res.data.map(formatLeague),
      total: res.meta.total,
    };
  });
}

export const getCachedLeagueById = useMemoize((leagueId: string, options?: { accessToken?: string }) =>
  getLeagueById(leagueId, options),
);

export function getLeagueById(leagueId: string, options?: { accessToken?: string }): Promise<ILeague | null> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return getLeaguesList().then(res => res.data.find(l => l.id === leagueId) || null);

  return useAPI<{ data: IResponseLeague }>(`/api/v1/leagues/${leagueId}`, {
    ...(options?.accessToken && { headers: { "X-Shared-Authorization": `Bearer ${options.accessToken}` } }),
  }).then(res => formatLeague(res.data));
}

export function createLeague(payload: ILeaguePayload): Promise<void> {
  const body = formatLeagueCreatePayload(payload);

  return useAPI("/api/v1/leagues/", { method: "POST", body }).then(() => {
    getCachedLeaguesList.clear();
  });
}

export function updateLeagueById(leagueId: string, data: Partial<ILeaguePayload>): Promise<void> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve();

  const payload = formatLeagueUpdatePayload(data);

  return useAPI(`/api/v1/leagues/${leagueId}`, { body: payload, method: "PATCH" }).then(() => {
    getCachedLeaguesList.clear();
  });
}

export function deleteLeagueById(leagueId: string): Promise<void> {
  return useAPI(`/api/v1/leagues/${leagueId}`, { method: "DELETE" }).then(() => {
    getCachedLeaguesList.clear();
  });
}

export function getTeamsByLeagueId(
  leagueId: string,
  query: Partial<IRequestParams & { relationships: string; seasons?: number[] }>,
): Promise<{ data: ITeam[]; total: number }> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData)
    return $fetch<IResponseClubs>("/mocks/data/clubs.json").then(res => ({
      data: formatTeamsData(res.data),
      total: res.meta.total,
    }));

  return useAPI<IResponseLeagueClubs>(`/api/v1/leagues/${leagueId}/league-clubs`, { query }).then(res => ({
    data: res.data.filter(i => i.relationships.club).map(i => formatTeam(i.relationships.club!)),
    total: res.meta.total,
  }));
}

export function removeTeamFromLeague(leagueId: string, teamId: string, season: number): Promise<string> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve("Teams removed successfully");

  return useAPI(`/api/v1/leagues/${leagueId}/remove`, { method: "POST", body: { clubId: teamId, season } });
}

export function joinTeamToLeague(leagueId: string, teamId: string, season: number): Promise<string> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData) return Promise.resolve("Teams added successfully");

  return useAPI(`/api/v1/leagues/${leagueId}/join`, { method: "POST", body: { clubId: teamId, season } });
}

export function getLeagueStandings(
  leagueId: string,
  options?: Partial<{
    accessToken: string;
    locale: string;
    season: number;
    round: number;
  }>,
): Promise<components["schemas"]["LeagueStandings"]> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData)
    return $fetch<{ data: components["schemas"]["LeagueStandings"] }>("/mocks/data/league/standings.json").then(
      res => res.data,
    );

  return $atlitFetch("/v1/leagues/{ID}/statistics/standings", {
    path: {
      ID: leagueId,
    },
    headers: getSharedHeaders({ locale: options?.locale }),
    query: {
      season: String(options?.season),
    },
  }).then(res => res.data);
}

export function getLeagueLeaderboards(
  leagueId: string,
  options?: Partial<{
    accessToken: string;
    locale: string;
    season: number;
  }>,
): Promise<components["schemas"]["LeagueLeaderboards"]> {
  const appConfig = useAppConfig();

  if (appConfig.useMockData)
    return $fetch<{ data: components["schemas"]["LeagueLeaderboards"] }>("/mocks/data/league/leaderboards.json").then(
      res => res.data,
    );

  return $atlitFetch("/v1/leagues/{ID}/statistics/leaderboards", {
    path: {
      ID: leagueId,
    },
    headers: getSharedHeaders({ locale: options?.locale }),
    query: {
      season: String(options?.season),
    },
  }).then(res => res.data);
}

export function getLeaguesCountries(): Promise<{ data: { code: string; title: string }[]; total: number }> {
  const appConfig = useAppConfig();
  const { getCountryTitleByCode } = useCountries();

  if (appConfig.useMockData)
    return $fetch<{ data: { code: string; title: string }[] }>("/json/countries.json").then((res) => {
      return {
        data: res.data.map(c => ({ code: c.code, title: getCountryTitleByCode(c.code) })),
        total: res.data.length,
      };
    });

  return useAPI<{ data: string[] }>("/api/v1/leagues/countries").then((res) => {
    return {
      data: res.data.map(c => ({ code: c, title: getCountryTitleByCode(c) })),
      total: res.data.length,
    };
  });
}

export function getLeaguesByTeamId(
  teamId: string,
  query?: Partial<IRequestParams>,
): Promise<{ data: ILeague[]; total: number }> {
  return useAPI<IResponseLeagues>(`/api/v1/clubs/${teamId}/leagues`, { query }).then((res) => {
    return {
      data: res.data.map(formatLeague),
      total: res.meta.total,
    };
  });
}
